var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Contacts"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',{staticClass:"is-narrow"}),_c('column',[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for contacts..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),(!_vm.$root.isPassiveUser())?_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                                    name: 'create-customer-contact',
                                    params: {
                                        customer: _vm.location.customer_uuid
                                    }
                                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Contact")])],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_vm._l((_vm.contacts.data),function(contact){return _c('div',{key:contact.id,staticClass:"box"},[_c('columns',[_c('column',[(!_vm.$root.isPassiveUser())?_c('router-link',{attrs:{"to":{
                            name: 'edit-customer-contact',
                            params: {
                                customer: _vm.location.customer_uuid,
                                contact: contact.uuid
                            }
                        }}},[_vm._v(" "+_vm._s(contact.full_name)+" ")]):_c('span',[_vm._v(_vm._s(contact.full_name))])],1)],1)],1)}),(_vm.contacts.data.length)?_c('pager',{attrs:{"pageable":_vm.contacts,"context":"contact","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.contacts.data.length,"heading":"There are no contacts available for display."}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }